import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { AiFillHeart } from 'react-icons/ai';
import TeamMembers from '@interness/web-core/src/components/modules/TeamMembers';
import Wrapper from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Heading from '@interness/web-core/src/components/text/Heading/Heading';
import FramedImage from '@interness/theme-sonora/src/components/FramedImage';
import CallToAction from '@interness/theme-sonora/src/components/CallToAction/CallToAction';
export const query = graphql`
    query {
        media: directusMediaCollection(name: {eq: "uber-uns"}) {
            media {
                file {
                    localFile {
                        name
                        publicURL
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Wrapper mdxType="Wrapper">
      <hr></hr>
  <Heading icon={<AiFillHeart mdxType="AiFillHeart" />} subtitle="Juwelier Grimm in Siegen" mdxType="Heading">Seit 1872 Uhrmacher- und
    Goldschmiedekunst</Heading>
  <div style={{
        textAlign: 'center'
      }}>
        <h2 {...{
          "id": "1872"
        }}>{`1872`}</h2>
        <p>{`  Urahn und Firmengründer Albert Grimm legt den Grundstein für das Familienunternehmen Juwelier Grimm mit der Gründung
eines Uhrenfachgeschäftes.`}</p>
        <h2 {...{
          "id": "1920"
        }}>{`1920`}</h2>
        <p>{`  Sohn Wilhelm Grimm (sen.) übernimmt nach dem Tod des Vaters den elterlichen Betrieb in der Wilhelmstraße 85.`}</p>
        <h2 {...{
          "id": "1962"
        }}>{`1962`}</h2>
        <p>{`  Wilhelm Grimm (jun.) übernimmt das Geschäft und wird 1964 mit nur 21 Jahren jüngster Uhrmachermeister im
Regierungsbezirk Arnsberg.`}</p>
        <h2 {...{
          "id": "1973"
        }}>{`1973`}</h2>
        <p>{`  Umzug und Erweiterung des Juweliergeschäftes in das neu erbaute Siegerlandzentrum.`}</p>
        <h2 {...{
          "id": "1985"
        }}>{`1985`}</h2>
        <p>{`  Michael Grimm, Urenkel des Firmengründers, setzt mit dem Beginn seiner Ausbildung die Uhrmachertradition von
Juwelier
Grimm in 4. Generation fort.`}</p>
        <h2 {...{
          "id": "1996"
        }}>{`1996`}</h2>
        <p>{`  Andreas Grimm richtet nach erfolgreich absolvierter Prüfung eine neue Goldschmiedewerkstatt innerhalb im
Traditionsunternehmen Juwelier Grimm ein.`}</p>
        <h2 {...{
          "id": "2000"
        }}>{`2000`}</h2>
        <p>{`  Die Meisterriege ist komplett: Mit Wilhelm Grimm, ehemaliger Ehren-Obermeister der Uhrmacherinnung, und seinen
Söhnen
Michael (Uhrmachermeister) und Andreas (Goldschmiedemeister) stehen gleich drei Meister hinter Juwelier Grimm in
Siegen, die ihr Handwerk in Perfektion beherrschen.`}</p>
        <h2 {...{
          "id": "2004"
        }}>{`2004`}</h2>
        <p>{`  Michael Grimm übernimmt die Geschäftsführung von Juwelier Grimm.`}</p>
        <h2 {...{
          "id": "2020"
        }}>{`2020`}</h2>
        <p>{`  Juwelier Grimm blickt im Jubiläumsjahr auf 150 Jahre Goldschmiede- und Uhrmachermeistertradition.`}</p>
  </div>
      <hr></hr>
  <Heading icon={<AiFillHeart mdxType="AiFillHeart" />} mdxType="Heading">Das Team</Heading>
  <TeamMembers mdxType="TeamMembers" />
      <hr></hr>
  <CallToAction mdxType="CallToAction" />
    </Wrapper>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      